.marker-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.marker {
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-label {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: black;
  margin-left: 5px;
  text-transform: uppercase;
  position: absolute;
  left: 15px;
  width: max-content;
  transform-origin: left;
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
  /* transition: opacity 0.3s ease, transform 0.3s ease; */
  transition: opacity 0.3s ease;
}
.marker-subtitle {
  text-transform: capitalize;
  margin-left: 5px;
  font-weight: 400;
  font-size: 0.85rem;
  color: #78909c;
}

.hide-labels .marker-label {
  opacity: 0;
  /* transform: scale(0.8); */
  pointer-events: none;
}
