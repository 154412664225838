.rdx-loader-line {
  fill: none;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: 60px;
  opacity: 0;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

.line-1 {
  animation: draw1 6s linear infinite, fadeInOut1 6s linear infinite;
}

.line-2 {
  animation: draw2 6s ease-in-out infinite, fadeInOut2 6s ease-in-out infinite;
}

.line-3 {
  animation: draw3 6s ease-in-out infinite, fadeInOut3 6s ease-in-out infinite;
}

.line-4 {
  animation: draw4 6s ease-in-out infinite, fadeInOut4 6s ease-in-out infinite;
}

.line-5 {
  animation: draw5 6s ease-in-out infinite, fadeInOut5 6s ease-in-out infinite;
}

.line-6 {
  animation: draw6 6s ease-in-out infinite, fadeInOut6 6s ease-in-out infinite;
}

@keyframes draw1 {
  0%,
  100% {
    stroke-dashoffset: 1000;
  }

  10%,
  40% {
    stroke-dashoffset: 0;
  }

  90% {
    stroke-dashoffset: 1000;
  }
}

@keyframes draw2 {
  0%,
  5%,
  100% {
    stroke-dashoffset: 1000;
  }

  20%,
  50% {
    stroke-dashoffset: 0;
  }

  90% {
    stroke-dashoffset: 1000;
  }
}

@keyframes draw3 {
  0%,
  10%,
  100% {
    stroke-dashoffset: 1000;
  }

  30%,
  60% {
    stroke-dashoffset: 0;
  }

  90% {
    stroke-dashoffset: 1000;
  }
}

@keyframes draw4 {
  0%,
  15%,
  100% {
    stroke-dashoffset: 1000;
  }

  30%,
  70% {
    stroke-dashoffset: 0;
  }

  90% {
    stroke-dashoffset: 1000;
  }
}

@keyframes draw5 {
  0%,
  12%,
  100% {
    stroke-dashoffset: 1000;
  }

  50%,
  60% {
    stroke-dashoffset: 0;
  }

  90% {
    stroke-dashoffset: 1000;
  }
}

@keyframes draw6 {
  0%,
  20%,
  100% {
    stroke-dashoffset: 1000;
  }

  60%,
  70% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: 1000;
  }
}

@keyframes fadeInOut1 {
  0%,
  90%,
  100% {
    opacity: 0;
  }

  1%,
  80% {
    opacity: 1;
  }
}

@keyframes fadeInOut2 {
  0%,
  90%,
  100% {
    opacity: 0;
  }

  1%,
  80% {
    opacity: 1;
  }
}

@keyframes fadeInOut3 {
  0%,
  90%,
  100% {
    opacity: 0;
  }

  1%,
  80% {
    opacity: 1;
  }
}

@keyframes fadeInOut4 {
  0%,
  90%,
  100% {
    opacity: 0;
  }

  1%,
  80% {
    opacity: 1;
  }
}

@keyframes fadeInOut5 {
  0%,
  90%,
  100% {
    opacity: 0;
  }

  1%,
  80% {
    opacity: 1;
  }
}

@keyframes fadeInOut6 {
  0%,
  90%,
  100% {
    opacity: 0;
  }

  1%,
  80% {
    opacity: 1;
  }
}
