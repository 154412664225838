@import "mapbox-gl/dist/mapbox-gl.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "map.css";
@import "loader.css";
@import "avoid-mapbox.css";

body {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans", sans-serif;
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-ibm {
  font-family: "IBM Plex Sans", sans-serif;
}
