.mapboxgl-ctrl-logo.mapboxgl-compact {
  opacity: 0;
}

.mapboxgl-ctrl-logo {
  opacity: 0;
}

.mapboxgl-ctrl-attrib {
  opacity: 0;
}

.mapboxgl-popup .mapboxgl-popup-tip {
  display: none;
}
.mapboxgl-popup .mapboxgl-popup-content {
  padding: 0;
}
.mapboxgl-popup .mapboxgl-popup-close-button {
  display: none;
}
